<template>
	<div class="fill-height d-flex flex-column justify-space-between">
		<v-container class="d-flex flex-column align-center">
			<v-form ref="form">
				<v-row dense>
					<v-col cols="6">
						<DateField
							:menu-disabled="inputDisable || isServiceShare"
							:disabled="inputDisable || isServiceShare"
							:reference-date="checkinModel.flightDatetime"
							:disabledPrevius="1"
							:disabledNext="1"
							v-model="pickupDatetime"
							:title="t('pickupDate')"
						/>
					</v-col>
					<v-col cols="6">
						<TimeField
							:rules="rulesTime"
							menu-disabled
							:disabled="inputDisable || isServiceShare"
							prepend-inner-icon="schedule"
							v-model="pickupDatetime"
							:title="`${t('pickupTime')}*`"
						/>
					</v-col>
					<v-col cols="12">
						<TimePicker
							:disabled="inputDisable || isServiceShare"
							ref="timepicker"
							v-model="pickupDatetime"
						/>
					</v-col>
				</v-row>
			</v-form>
		</v-container>

		<v-container class="mb-3">
			<v-card-text
				v-if="existAirport"
				class="mx-n2 mb-1 font-weight-bold flex text-center"
			>
				{{ isArrival ? t("message") : t("messageDropoff") }}
			</v-card-text>
			<LayoutBtn
				:edit="$t('global.btnCancel')"
				:confirm="$t('global.btnSave')"
				:disabled-confirm="!isValid || inputDisable"
				@click-edit="cancel()"
				@click-confirm="save()"
			/>
		</v-container>
	</div>
</template>

<script>
import LayoutBtn from "@/layouts/LayoutBtn";
import DateField from "@/components/field/DateField";
import TimeField from "@/components/field/TimeField";
import TimePicker from "@/components/field/TimePickerSimple";
import { mapFields } from "@/helpers/mapFields";
import { mapGetters, mapMutations, mapState } from "vuex";
import dayjs from "dayjs";
export default {
	components: {
		TimePicker,
		LayoutBtn,
		DateField,
		TimeField
	},
	data: () => ({
		isValid: false
	}),
	watch: {
		pickupDatetime() {
			this.isValid = this.$refs?.form?.validate() || false;
		}
	},
	computed: {
		...mapGetters("service", [
			"isArrival",
			"getBasePath",
			"existAirport",
			"isServiceShare",
			"inputDisable"
		]),
		...mapState("service", ["checkinModel", "auxModel", "dateReference"]),
		...mapFields({
			fields: ["pickupDatetime"],
			base: "auxModel",
			mutation: "SET_UPDATE_AUXMODEL",
			module: "service"
		}),
		diffMinute() {
			const flightDate = dayjs(this.dateReference);
			const pickupDate = dayjs(this.auxModel.pickupDatetime);
			return pickupDate.diff(flightDate, "minute") || 0;
		},
		rulesTime: vm => {
			return !vm.isArrival
				? [() =>
							vm.diffMinute <= 0 ||
							vm.t("timeOfFlighAfter", {
								minute: Math.abs(vm.diffMinute)
							})
				]:[() =>
							vm.diffMinute >= 0 ||
							vm.t("timeOfFlighBefore", {
								minute: Math.abs(vm.diffMinute)
							})];
		}
	},
	methods: {
		...mapMutations("service", [
			"SET_MODEL",
			"SET_AUXMODEL",
			"SET_RECOMMENDED"
		]),
		t(key, options = {}) {
			return this.$t(`section.schedule.${key}`, options);
		},
		cancel() {
			this.SET_AUXMODEL(this.checkinModel);
			this.back();
		},
		save() {
			if (!this.$refs.form.validate()) return;
			this.SET_MODEL(this.auxModel);
			this.SET_RECOMMENDED(false);
			this.back();
		},
		back() {
			this.$router.replace(this.getBasePath(this.$route.meta.back));
		}
	}
};
</script>
